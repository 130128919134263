<template>
  <div class="received-coach-balance" :class="[{'p-2 vx-card': $route.name === 'receiveCoachBalance'}]">

    <div class="received-coach-balance-inputs">
      <div class="received-coach-balance-fields">
        <custom-price-input v-model="receivedBalance"
                            :classes="{'w-full': true}"
                            :label="`${$t('coaches.receive.labels.receivedBalance')}`"/>
      </div>

      <div class="received-coach-balance-fields">
        <custom-validate-input v-model="receivedBalanceTransferId"
                               :classes="{'w-full': true}"
                               :regex="$validator('regex.coaches.receive.transferId')"
                               :rtl="!$vs.rtl"
                               :align="$vs.rtl ? 'left' : 'right'"
                               :label="`${$t('coaches.receive.labels.transferId')}`"/>
      </div>
    </div>

    <div class="received-coach-balance-details">
      <div class="received-coach-balance-fields">
        <custom-price-input :disabled="true"
                            :value="totalBalance"
                            :classes="{'w-full': true}"
                            :is-empty="true"
                            :label="`${$t('coaches.receive.labels.totalBalance')}`"/>
      </div>

      <div class="received-coach-balance-fields">
        <custom-price-input :disabled="true"
                            :value="receivedPayableBalance"
                            :classes="{'w-full': true}"
                            :is-empty="true"
                            :label="`${$t('coaches.receive.labels.receivedPayableBalance')}`"/>
      </div>
    </div>

    <button v-show="false"
            id="receivePaymentGatewayBalanceBTN"
            @click="receiveBalance"/>
  </div>
</template>

<script>
import CustomPriceInput from '@/components/customInput/customPriceInput'
import CustomValidateInput from '@/components/customInput/customValidateInput'
import {getCoachBalance, receiveCoachBalance} from "../../../../http/requests/coaches";

export default {
  name: 'receivedCoachBalance',
  components: {CustomValidateInput, CustomPriceInput},
  props: {
    coachId: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      totalBalance: {
        value: '',
        isValid: true
      },
      receivedPayableBalance: {
        value: '',
        isValid: true
      },
      receivedBalance: {
        value: '',
        isValid: true
      },
      receivedBalanceTransferId: {
        value: '',
        isValid: true
      },
      actions: {
        toolbar: [
          {
            id: 'receivePaymentGatewayBalanceBTN',
            icon: 'SAVE',
            iconPack: 'useral',
            color: 'success'
          }
        ],
        leftToolbar: [
          {
            id: 'routeBackBTN',
            route: {name: this.$route.name === 'receiveCoachBalance' ? 'coach' : 'coaches'},
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      }
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions)
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)
    this.$store.dispatch('setPageTitle', this.$t('coaches.receive.title'))
    this.getBalance()
  },
  methods: {
    getBalance () {
      getCoachBalance(this.coachId || this.$route.params.id).then(response => {
        const receive = response.data.data
        this.totalBalance.value = receive.total_balance * -1
        this.receivedPayableBalance.value = receive.payable_balance * -1
        // this.receivedBalance.value = response.data.data.yesterday_balance
      })
    },
    receiveBalance () {
      const receive = {
        price: parseInt(this.receivedBalance.value.toString().replaceAll(',', '')),
        transaction_reference_id: this.receivedBalanceTransferId.value
      }
      receiveCoachBalance(this.coachId || this.$route.params.id, receive).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('coaches.notifications.receive.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$emit('received')
      }).catch(error => {

        switch (error.response.status) {
        case 400:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('coaches.receive.notifications.balanceIsNotEnough'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          break

        case 422:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('coaches.receive.notifications.incorrectPriceOrId'),
            color: 'danger',
            time: 5000,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          break

        default:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('coaches.notifications.receive.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.received-coach-balance {
  .received-coach-balance-inputs,
  .received-coach-balance-details {
    max-width: 400px;
  }

  .received-coach-balance-inputs {
    background: #cecece22;
    border-radius: .5rem;
    border: 1px solid #cecece;
    padding: 0 10px;

    .received-coach-balance-fields {

      .input-label {
        backdrop-filter: sepia(1);
        background: linear-gradient(180deg, #f8f8f8, #ffffff);
      }
    }
  }

  .received-coach-balance-fields {
    margin-top: 10px;
  }
}
</style>
