<template>
  <div class="u-input custom-price-input" :class="[$vs.rtl ? 'dir-rtl' : 'dir-ltr']">
    <label class="u-input-box" :class="[{'is-not-valid': !price.isValid, 'disabled': disabled}, classes || {}]" :style="getStyles">
<!--      <span class="input-label" :class="{'is-focus': price.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>-->

      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="u-input-label text-subtitle" :class="[{'is-focus': price.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <input type="text"
             id="priceInput"
             :class="[{'disabled': disabled}]"
             :style="inputStyle"
             inputmode="decimal"
             @input="handleInputValue"
             @contextmenu="handleContextMenuClicked($event)"
             @keydown.enter="handleKeydownEnter"
             @focus="event => $emit('focus', event)"
             :autocomplete="false"
             :name="Math.random()"
             :placeholder="placeholder || value.placeholder || ''"
             :disabled="disabled"
             v-model="price.value">
      <span class="input-currency" :style="inputStyle">{{ currency || $locale.currency() }}</span>
    </label>
    <button v-if="price.value.length > 0 && !disabled && clearable" class="clear-btn" @click="handleClearInput">
      <custom-icon icon="TIMES" color="danger" height="20px" width="20px" />
    </button>
  </div>
</template>

<script>
import {addComma} from '@/assets/js/functions'
import {customParseFloat} from "../../assets/js/functions";
import CustomIcon from "../customIcon/customIcon";

export default {
  name: 'customPriceInput',
  components: {CustomIcon},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelPlaceholder: {
      type: String,
      default: ''
    },
    labelPosition: {
      type: String,
      default: 'outline'
    },
    isEmpty: {
      type: Boolean,
      default: true
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    currency: {
      type: String,
      default: ''
    },
    classes: {
      type: [Object, Array, String],
      default () {
        return {}
      }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    max: {
      type: [Number, String],
      default: () => 0
    },
    inputStyle: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      price: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    this.validateValue()

    setTimeout(() => {
      if (this.autoFocus) {
        let priceInput = window.document.getElementById('priceInput')
        if (priceInput) {
          priceInput.focus()
        }
      }
    }, 100)

  },
  methods: {
    handleInputValue () {
      this.addComma()
      this.validateValue()
      this.$emit('input', {value: this.price.value.split(',').join(''), placeholder: this.value.placeholder, isValid: this.price.isValid})
    },
    handleClearInput () {
      // this.price.value = ''
      this.$emit('input', {value: '', placeholder: this.value.placeholder, isValid: this.price.isValid})
    },
    validateValue () {
      if (this.isEmpty && this.price.value === '') {
        this.price.isValid = true
      } else if (this.max > 0) {
        this.price.isValid = customParseFloat(this.value.value || '0') <= this.max
      } else {
        this.price.isValid = this.price.value.toString().length > 0
      }
    },
    addComma () {
      this.price.value = addComma(this.price.value)
    },
    initValues () {
      this.price.value = this.value.value !== null ? addComma(this.value.value) || '' : ''
      this.price.isValid = this.value.isValid
      this.validateValue()
    },
    handleContextMenuClicked (event) {
      const payload = {
        actions: {
          clipboard: this.price.value,
          paste: true
        },
        event: event
      }
      this.$store.dispatch('helper/contextMenuClicked', payload)
    },
    handleKeydownEnter () {
      this.$emit('pressEnter')
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-price-input {
  position: relative;
  margin: 15px 0 25px;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    .input-currency {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 10px;
    }

    input {
      width: calc(100% - 40px);
      background: transparent;
      border: none;
      outline: none !important;
      direction: rtl;

      &.disabled {
        opacity: 0.75;
      }
    }
  }

  .clear-btn {
    position: absolute;
    top: calc(100% / 2 - 8px);
    left: 5px;
    height: 20px;
    width: 20px;
    border: 0;
    background-color: inherit;

    &:hover {
      cursor: pointer;
    }

    .icon-component {
      position: relative;
      right: 5px;
      top: -2px;
    }
  }

  &.dir-ltr {
    label {
      input {
        direction: ltr;
        text-align: left;
      }
    }

    .clear-btn {
      right: 0;
      left: unset;

      .icon-component {
        right: 0;
      }
    }
  }
}
</style>
